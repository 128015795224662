/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {x} from '@xstyled/styled-components';
import {Image, Quote, QuoteAuthor} from "smooth-doc/components";
import eventStorming from './images/DLES-Brandolini-transparent.png';
import eventStormingFromVisionToDetail from './images/event-storming-from-vision-to-detail.png';
import eventStormingTimelineNarratives from './images/event-storming-timeline-narratives.png';
import eventStormingDesignCanvas from './images/event-storming-design-canvas.png';
import eventStormingZdlMapping from './images/EventStorming-ZDL-Mapping.png';
import eventStormingTimeline from './images/event-storming-timeline.png';
import eventStormingWithCommandsAndPolicies from './images/event-storming-with-commands-and-policies.png';
import eventStormingWithAggregates from './images/event-storming-with-aggregates.png';
import eventStormingBoundedContexts from './images/event-storming-bounded-contexts.png';
import eventDark from './images/event-storming/event.dark.png';
import eventLight from './images/event-storming/event.light.png';
import commandDark from './images/event-storming/command.dark.png';
import commandLight from './images/event-storming/command.light.png';
import userCommandDark from './images/event-storming/user-command-event.dark.png';
import userCommandLight from './images/event-storming/user-command-event.light.png';
import policyCommandDark from './images/event-storming/policies.dark.png';
import policyCommandLight from './images/event-storming/policies.light.png';
import commandSystemEventDark from './images/event-storming/command-system-event.dark.png';
import commandSystemEventLight from './images/event-storming/command-system-event.light.png';
import commandAggregateEventDark from './images/event-storming/command-aggregate-event.dark.png';
import commandAggregateEventLight from './images/event-storming/command-aggregate-event.light.png';
import readModelCommandAggregateEventDark from './images/event-storming/read-model-command-aggregate-event.dark.png';
import readModelCommandAggregateEventLight from './images/event-storming/read-model-command-aggregate-event.light.png';
import boundedContextMapping from '../../../images/home/bounded-context-mapping.svg';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    img: "img",
    h3: "h3",
    h4: "h4"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "event-storming",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#event-storming",
    "aria-label": "event storming permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Event Storming"), "\n", React.createElement(_components.p, null, "Flexible workshop format for collaborative exploration of complex business domains"), "\n", React.createElement(Quote, null, "Event Storming provides a shared language that bridges the gap between business experts and technical teams."), "\n", React.createElement(Image, {
    src: eventStorming,
    alt: "Event Storming"
  }), "\n", React.createElement(_components.h2, {
    id: "event-storming-1",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#event-storming-1",
    "aria-label": "event storming 1 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Event Storming"), "\n", React.createElement(_components.p, null, "It was created by Alberto Brandolini, and is described in his book ", React.createElement(_components.a, {
    href: "https://leanpub.com/introducing_eventstorming"
  }, "Introducing EventStorming"), "."), "\n", React.createElement(_components.p, null, "Event Storming is a collaborative workshop technique used to explore complex business domains."), "\n", React.createElement(_components.p, null, "Its primary goal is to create a shared understanding among stakeholders, including business experts and technical teams."), "\n", React.createElement(_components.p, null, "It helps to make complex processes more tangible."), "\n", React.createElement(_components.p, null, "Events represent significant occurrences in the domain that trigger changes or reactions."), "\n", React.createElement(_components.p, null, "Commands and Policies are also used to represent actions and rules within the domain."), "\n", React.createElement(_components.p, null, "Design Level Event Storming helps in identifying Aggregates and Entities within the domain. Aggregates are clusters of related objects that are treated as a single unit."), "\n", React.createElement(_components.h2, {
    id: "zdl--a-domain-modeling-language-for-ddd-and-event-storming",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#zdl--a-domain-modeling-language-for-ddd-and-event-storming",
    "aria-label": "zdl  a domain modeling language for ddd and event storming permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "ZDL ~ A Domain Modeling Language for DDD and Event Storming"), "\n", React.createElement(_components.p, null, "ZenWave Domain Language (", React.createElement(_components.a, {
    href: "/docs/event-driven-design/zenwave-domain-language"
  }, "ZDL"), ") is a Domain Specific Language that maps Event Storming discoveries with DDD principles in mind into a developer friendly format."), "\n", React.createElement(_components.p, null, "It works as an Ubiquitous Language, retaining the language of the domain and because is machine friendly it propagates automatically to documentation, code and tests."), "\n", React.createElement(Image, {
    alt: "Event-Storming to ZDL Mapping",
    src: eventStormingZdlMapping
  }), "\n", React.createElement(_components.p, null, "Jump to ", React.createElement(_components.a, {
    href: "/docs/event-driven-design/bounded-context-canvas/"
  }, "Bounded Context Canvas"), " for details about how to map Event-Storming concepts into ZDL format."), "\n", React.createElement(_components.h2, {
    id: "from-vision-to-detail",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#from-vision-to-detail",
    "aria-label": "from vision to detail permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "From Vision To Detail"), "\n", React.createElement(Image, {
    alt: "Event Storming from Vision to Detail",
    src: eventStormingFromVisionToDetail
  }), "\n", React.createElement(_components.p, null, "(Source: ", React.createElement(_components.a, {
    href: "https://es.slideshare.net/ziobrando/50000-orange-stickies-later"
  }, "https://es.slideshare.net/ziobrando/50000-orange-stickies-later"), ")"), "\n", React.createElement(_components.h2, {
    id: "big-picture-eventstorming",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#big-picture-eventstorming",
    "aria-label": "big picture eventstorming permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Big Picture EventStorming"), "\n", React.createElement(Image, {
    alt: "Big Picture EventStorming",
    src: eventStormingTimelineNarratives
  }), "\n", React.createElement(_components.p, null, "Big Picture EventStorming is ideal for Analysis of a whole Business Domain or Subdomain. Beyond silos, it requires the participation of business experts"), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "https://miro.medium.com/v2/resize:fit:1100/format:webp/1*e1bMa2Q-hVumzGJO27jM5g.jpeg",
    alt: "Big Picture EventStorming"
  }), "\r\n(Source: ", React.createElement(_components.a, {
    href: "https://medium.com/@springdo/a-facilitators-recipe-for-event-storming-941dcb38db0d"
  }, "https://medium.com/@springdo/a-facilitators-recipe-for-event-storming-941dcb38db0d"), ")"), "\n", React.createElement(_components.h2, {
    id: "design-level-eventstorming-for-designing-event-driven-systems-for-a-bonded-context",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#design-level-eventstorming-for-designing-event-driven-systems-for-a-bonded-context",
    "aria-label": "design level eventstorming for designing event driven systems for a bonded context permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Design Level EventStorming for Designing Event-Driven Systems for a Bonded Context"), "\n", React.createElement(Image, {
    alt: "Design Level EventStorming",
    src: eventStormingDesignCanvas
  }), "\n", React.createElement(_components.p, null, "Design Level EventStorming is ideal for Designing Event-Driven Systems for a given Bonded Context and while it can be performed by the development team alone, it is recommended to request feedback and validation from domain experts."), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "https://mrpicky.dev/wp-content/uploads/2021/12/DLES-offer-1024x589.jpg",
    alt: "Design Level EventStorming"
  }), "\r\n(Source: ", React.createElement(_components.a, {
    href: "https://mrpicky.dev/design-level-event-storming-with-examples/"
  }, "https://mrpicky.dev/design-level-event-storming-with-examples/"), ")"), "\n", React.createElement(_components.h2, {
    id: "event-storming-elements",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#event-storming-elements",
    "aria-label": "event storming elements permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Event Storming Elements"), "\n", React.createElement(_components.h3, {
    id: "events",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#events",
    "aria-label": "events permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Events"), "\n", React.createElement(_components.p, null, "Events play a central role in Event Storming. They represent significant occurrences in the domain that trigger changes or reactions."), "\n", React.createElement(Image, {
    dark: eventDark,
    light: eventLight,
    alt: "Event Storming - Event",
    style: {
      maxWidth: '650px'
    }
  }), "\n", React.createElement(_components.h3, {
    id: "commands-and-policies",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#commands-and-policies",
    "aria-label": "commands and policies permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Commands and Policies"), "\n", React.createElement(_components.p, null, "Commands and Policies are used to represent actions and rules within the domain."), "\n", React.createElement(Image, {
    dark: commandDark,
    light: commandLight,
    alt: "Event Storming - Command",
    style: {
      maxWidth: '600px'
    }
  }), "\n", React.createElement(_components.h4, {
    id: "user-initiated-command-produces-event",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#user-initiated-command-produces-event",
    "aria-label": "user initiated command produces event permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "User Initiated Command Produces Event"), "\n", React.createElement(_components.p, null, "Commands can be initiated by users."), "\n", React.createElement(Image, {
    dark: userCommandDark,
    light: userCommandLight,
    alt: "Event Storming - User Initiated Command Produces Event",
    style: {
      maxWidth: '700px'
    }
  }), "\n", React.createElement(_components.h4, {
    id: "policy-initiated-command",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#policy-initiated-command",
    "aria-label": "policy initiated command permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Policy Initiated Command"), "\n", React.createElement(_components.p, null, "Commands can also be initiated by policies. Rules triggering in the background, spanning for other systems events, temporal triggers, external systems, etc."), "\n", React.createElement(Image, {
    dark: policyCommandDark,
    light: policyCommandLight,
    alt: "Event Storming - Policy Initiated Command",
    style: {
      maxWidth: '800px'
    }
  }), "\n", React.createElement(_components.h3, {
    id: "command-invoked-on-system-produces-event",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#command-invoked-on-system-produces-event",
    "aria-label": "command invoked on system produces event permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Command invoked on System produces Event"), "\n", React.createElement(_components.p, null, "Commands do not directly triggers events, but they are invoked on a system, which in turn produces events."), "\n", React.createElement(Image, {
    dark: commandSystemEventDark,
    light: commandSystemEventLight,
    alt: "Event Storming - Command invoked on System produces Event",
    style: {
      maxWidth: '800px'
    }
  }), "\n", React.createElement(_components.h3, {
    id: "command-invoked-on-aggregate-produces-event",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#command-invoked-on-aggregate-produces-event",
    "aria-label": "command invoked on aggregate produces event permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Command invoked on Aggregate produces Event"), "\n", React.createElement(_components.p, null, "DDD systems are comprised of Aggregates, which are clusters of related objects that are treated as a single unit. Commands are invoked on Aggregates, which in turn produces events."), "\n", React.createElement(Image, {
    dark: commandAggregateEventDark,
    light: commandAggregateEventLight,
    alt: "Event Storming - Command invoked on Aggregate produces Event",
    style: {
      maxWidth: '850px'
    }
  }), "\n", React.createElement(_components.h3, {
    id: "read-models-in-commands-and-events",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#read-models-in-commands-and-events",
    "aria-label": "read models in commands and events permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Read Models in Commands and Events"), "\n", React.createElement(_components.p, null, "Read Models represents Value Objects associated to Commands and Events."), "\n", React.createElement(Image, {
    dark: readModelCommandAggregateEventDark,
    light: readModelCommandAggregateEventLight,
    alt: "Event Storming - Command invoked on Aggregate produces Event",
    style: {
      maxWidth: '850px'
    }
  }), "\n", React.createElement(_components.h2, {
    id: "event-storming-steps",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#event-storming-steps",
    "aria-label": "event storming steps permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Event Storming Steps"), "\n", React.createElement(_components.h3, {
    id: "establish-the-timeline",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#establish-the-timeline",
    "aria-label": "establish the timeline permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Establish the Timeline"), "\n", React.createElement(_components.p, null, "First step is discover all relevant events that happened in the domain and sort them in a timeline from left to right."), "\n", React.createElement(_components.p, null, "Find ", React.createElement("strong", {
    className: "orange"
  }, "pivotal events"), ": those events where the flow splits into different paths or joins back together. These pivotal events would be ", React.createElement("strong", {
    className: "orange"
  }, "good candidates for Context boundaries"), "."), "\n", React.createElement(Image, {
    src: eventStormingTimeline,
    alt: "Event Storming Timeline"
  }), "\n", React.createElement(_components.h3, {
    id: "join-events-with-commands-and-policies",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#join-events-with-commands-and-policies",
    "aria-label": "join events with commands and policies permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Join events with commands and policies"), "\n", React.createElement(_components.p, null, "Next join events (facts) with commands and policies (actions and rules)."), "\n", React.createElement(Image, {
    src: eventStormingWithCommandsAndPolicies,
    alt: "Event Storming with Commands and Policies"
  }), "\n", React.createElement(_components.h3, {
    id: "identify-aggregates",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#identify-aggregates",
    "aria-label": "identify aggregates permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Identify Aggregates"), "\n", React.createElement(_components.p, null, "Identify Aggregates and Entities within the domain. Alternatively you can just identify Systems and leave Aggreate discovery for a posterior technical design phase."), "\n", React.createElement(Image, {
    src: eventStormingWithAggregates,
    alt: "Event Storming with Aggregates"
  }), "\n", React.createElement(_components.h3, {
    id: "split-into-bounded-contexts",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#split-into-bounded-contexts",
    "aria-label": "split into bounded contexts permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Split into Bounded Contexts"), "\n", React.createElement(_components.p, null, "A Bounded Context is a self-contained entity with its own domain model and its own Ubiquitous Language that is not affected by the outside world."), "\n", React.createElement(_components.p, null, "They help in organizing the domain into manageable parts and in defining the interactions between different parts of the system."), "\n", React.createElement(Image, {
    src: eventStormingBoundedContexts,
    alt: "Event Storming with Bounded Contexts"
  }), "\n", React.createElement(_components.h3, {
    id: "describe-the-bounded-contexts-using-bounded-context-canvas-and-zdl-model-language",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#describe-the-bounded-contexts-using-bounded-context-canvas-and-zdl-model-language",
    "aria-label": "describe the bounded contexts using bounded context canvas and zdl model language permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Describe the Bounded Contexts using Bounded Context Canvas and ZDL Model Language"), "\n", React.createElement(_components.p, null, "Use a Bounded Context Canvas to describe the inputs, outputs, aggregates and policies of  Bounded Context."), "\n", React.createElement(_components.p, null, "You can describe the Bounded Context directly using the developer friendly and machine friendly ZDL Model Language."), "\n", React.createElement(Image, {
    src: boundedContextMapping,
    alt: "Bounded Context Mapping with ZDL",
    width: "100%"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
